@tailwind base;
@tailwind components;
@tailwind utilities;

.split {
  display: flex;
  flex-direction: row;
}

.gutter {
  background-color: #eee;
  background-repeat: no-repeat;
  background-position: 50%;
}

.gutter.gutter-horizontal {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==');
  cursor: col-resize;
}

.backgroundLogin {
  background-color: #7b2cbf;
  background-image: url('https://public-mickaeltordjman.s3.eu-west-3.amazonaws.com/logoOsteoapp.svg');
  background-attachment: fixed;
  background-size: cover;
}
